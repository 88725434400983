.progressBar {
  width: 100%;
  height: 20px;
  background-color: #f3f3f3;
  border-radius: 20px;
}

.progressBarFill {
  height: 100%;
  border-radius: 20px;
  background-color: #6bd731;
  transition: width 0.5s ease;
}

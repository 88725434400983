.navbar {
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-6)
  );
  height: 100vh;
  width: rem(300px);
  display: flex;
  flex-direction: column;
  border-right: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.wrapper {
  display: flex;
  flex: 1;
}

.aside {
  flex: 0 0 rem(60px);
  background-color: var(--mantine-color-body);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
}

.main {
  flex: 1;
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-6)
  );
}

.mainLink {
  width: rem(44px);
  height: rem(44px);
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));

  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-5)
    );
  }

  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-bright-green-light);
      color: var(--mantine-color-bright-green-light-color);
    }
  }
}

.title {
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  margin-bottom: var(--mantine-spacing-xl);
  background-color: var(--mantine-color-body);
  padding: var(--mantine-spacing-md);
  padding-top: rem(18px);
  height: rem(60px);
  border-bottom: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  height: rem(60px);
  padding-top: var(--mantine-spacing-md);
  border-bottom: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
  margin-bottom: var(--mantine-spacing-xl);
}

.link {
  display: block;
  text-decoration: none;
  border-top-right-radius: var(--mantine-radius-md);
  border-bottom-right-radius: var(--mantine-radius-md);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  padding: 0 var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-sm);
  margin-right: var(--mantine-spacing-md);
  font-weight: 500;
  height: rem(44px);
  line-height: rem(44px);

  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-1),
      var(--mantine-color-dark-5)
    );
    color: light-dark(var(--mantine-color-dark), var(--mantine-color-light));
  }

  &[data-active] {
    &,
    &:hover {
      border-left-color: var(--mantine-color-bright-green-filled);
      background-color: var(--mantine-color-bright-green-filled);
      color: var(--mantine-color-white);
    }
  }
}
